import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Index from "./pages/index";
import Download from "./pages/download";
import Login from "./pages/login";
import Navbar from "./components/Navbar";

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/download" element={<Download />} />
        <Route path="/login" element={<Login/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;