import React, {useState} from "react";

const Login: React.FC = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    return (
        <>
            <div className="flex w-full max-w-[1200px] mx-auto flex-col items-center justify-center h-full px-5 -mt-12">
                <div>
                    <img src="static/logo.webp" alt="WhiteToolsBrowser Logo" draggable="false" className="h-12 select-none" />
                </div>
                <div className="text-center mt-[29px] flex flex-col -gap-y-2">
                    <h1 className="font-['Inter'] font-bold text-2xl text-[#F3F3F3]">Змінити пароль</h1>
                    <span className="font-['Inter'] text-xs text-[#909090]">Настала пора для змін!</span>
                </div>
                <div className="mt-[49px] grid grid-cols-1 gap-y-6 w-full md:max-w-[488px] max-w-[335px]">
                    <div className="flex flex-col gap-y-2">
                        <span className="font-['Inter'] text-[16px]">Юзернейм</span>
                        <div className="rounded-md flex flex-row items-center border border-white border-opacity-[0.06] bg-white bg-opacity-[0.08] px-4 py-3">
                            <img src="static/user.svg" alt="User Icon" draggable="false" className="select-none w-5 h-5" />
                            <input type="text" placeholder="Юзернейм" className="bg-transparent outline-none border-none w-full ml-3" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <span className="font-['Inter'] text-[16px]">Пароль</span>
                        <div className="rounded-md flex flex-row items-center border border-white border-opacity-[0.06] bg-white bg-opacity-[0.08] px-4 py-3">
                            <img src="static/lock.svg" alt="User Icon" draggable="false" className="select-none w-5 h-5" />
                            <input type={showPassword ? "text" : "password"} placeholder="Пароль" className="bg-transparent outline-none border-none w-full ml-3" />
                            <button onClick={() => setShowPassword(!showPassword!)}>
                                {
                                    showPassword ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <span className="font-['Inter'] text-[16px]">Новий пароль</span>
                        <div className="rounded-md flex flex-row items-center border border-white border-opacity-[0.06] bg-white bg-opacity-[0.08] px-4 py-3">
                            <img src="static/lock.svg" alt="User Icon" draggable="false" className="select-none w-5 h-5" />
                            <input type={showNewPassword ? "text" : "password"} placeholder="Новий пароль" className="bg-transparent outline-none border-none w-full ml-3" />
                            <button onClick={() => setShowNewPassword(!showNewPassword!)}>
                                {
                                    showNewPassword ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                    <button className="bg-gradient-to-br from-[#DBDBDB] to-white rounded-lg py-3">
                        <span className="font-['Inter'] font-bold text-[#0E0F11] text-[16px]">Змінити пароль</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Login;