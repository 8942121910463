import React from "react";
import Footer from "../components/Footer";
import Marquee from "react-fast-marquee";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";

const Index: React.FC = () => { 
    const serviceDemo = (e: React.MouseEvent<HTMLImageElement>) => {
        e.preventDefault();
        window.location.href = "/play";
    }

    return (
        <>
            <div className="flex max-w-[1280px] !w-full flex-col mx-auto items-center mt-[84px] px-5 relative">
                <div className="bg-gradient-to-r from-transparent via-wtb-border-color to-transparent p-[1px] flex justify-center items-center">
                    <div className="rounded-full py-[5px] px-[15px] bg-[#0E0E0E] w-max">
                        <span className="text-[#646464] text-sm md:text-base select-none transition-all duration-200 hover:text-white">Топ 1 браузер для економії на сервісах</span>
                    </div>
                </div>
                <div className="mt-[11.5px] flex justify-center flex-col">
                    <h1 className="flex flex-col text-center font-['Inter'] text-[22.4px] md:text-5xl">
                        <span className="text-[#909090] font-medium">Перший Український Браузер</span>
                        <span className="font-semibold">Для Економії На Сервісах</span>
                    </h1>
                    <p className="mt-3 text-[#909090] font-['Inter'] text-xs md:text-base flex flex-col text-center">
                        <span>Використовуй популярні сервіси зі знижкою до -80%</span>
                        <span>З нами не потрібно турбуватися про бани, ліміти та інші проблеми</span>
                    </p>
                </div>
                <div className="mt-5 flex flex-col justify-center items-center">
                    <span className="text-[#909090] text-xs font-['Inter']">
                        У нас в наявності:
                    </span>
                    <div className="mt-[22px]">
                        <Marquee>
                            <div className="flex flex-row gap-x-[15px]">
                                <img src="static/ChatGPT.svg" alt="ChatGPT logo" className="select-none" draggable="false" />
                                <img src="static/freepik.svg" alt="Freepik logo" className="select-none" draggable="false" />
                                <img src="static/Semrush.svg" alt="Semrush logo" className="select-none" draggable="false" />
                                <img src="static/TGStat.svg" alt="TGStat logo" className="select-none" draggable="false" />
                                <img src="static/Flaticon.svg" alt="FlatIcon logo" className="select-none" draggable="false" />
                                <img src="static/Midjourney.svg" alt="Midjourney logo" className="select-none" draggable="false" />
                                <img src="static/Canva.svg" alt="Canva logo" className="select-none" draggable="false" />
                                <img src="static/Envato.svg" alt="Envato logo" className="select-none" draggable="false" />
                                <img src="static/Pipiads.svg" alt="PiPiAds logo" className="select-none" draggable="false" />
                                <img src="static/Serpstat.svg" alt="Serpstat logo" className="select-none" draggable="false" />
                                <img src="static/Adheart.svg" alt="AdHeart logo" className="select-none mr-[15px]" draggable="false" />
                            </div>
                        </Marquee>
                    </div>
                    <a href="#" className="mt-[23px] rounded-full bg-[#00CFE1] text-black text-base font-['Inter'] font-medium px-[15px] md:px-[29px] py-[10.5px] transition-all duration-200 hover:brightness-125">
                        Почати економити
                    </a>
                </div>
                <div className="mt-8 -z-10 flex flex-col relative items-center wtPromoImage">
                    <img src="static/wtPromoImage.svg" alt="WhiteToolsBrowser Promo" className="select-none !-z-10 pointer-events-none lg:-mt-[576px] md:-mt-[376px] -mt-[176px]" draggable="false" onClick={serviceDemo} />
                    
                </div>
                <div className="flex w-full flex-col relative items-center">
                    <a href="#" className="rounded-full bg-white px-6 py-[8px] md:py-[10px] inline-flex flex-row items-center bottom-0 md:bottom-16 gap-x-[5px] absolute transition-all duration-200 hover:brightness-125">
                        <span className="font-['Inter'] font-medium text-[15px] text-black">Переглянути як працює браузер</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-black">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                        </svg>
                    </a>
                </div>
                <div className="flex flex-col text-center mt-12">
                    <h2 className="font-['Inter'] font-bold text-base md:text-3xl">
                        Видаємо доступи швидко та без заморочок
                    </h2>
                    <p className="mt-[15.59px] text-[#909090] font-['Inter'] text-[10px] md:text-lg flex flex-col text-center">
                        <span>Ми зробили все, щоб у Вас не виникало проблем зі входом</span>
                        <span>Наша команда завжди готова швидко надати доступ до потрібних Вам сервісів</span>
                        <span>Після оплати достатньо зайти у браузер, натиснути біля придбаного сервісу кнопку "Запуск" та користуватися</span>
                    </p>
                    <div className="mt-[39px]">
                        <div className="select-none flex gap-y-4 gap-x-12 flex-wrap justify-center">
                            <div className="bg-[#272727] rounded-2xl py-[18px] inline-flex px-6 flex-col gap-y-2">
                                <div className="flex flex-col gap-y-[1px] items-start">
                                    <img src="static/ChatGPTFull.svg" alt="ChatGPT Service Icon" className="select-none w-9 h-9" draggable="false" />
                                    <span className="font-bold font-['Inter'] text-lg md:text-[27px]">ChatGPT</span>
                                    <span className="font-medium text-sm -mt-1">#Нейромережа</span>
                                </div>
                                <div className="flex justify-between flex-row items-center">
                                    <span className="mr-28 font-medium text-xs">Доступ до: 25.09.2024</span>
                                    <div className="border-2 border-gray-200 rounded-md py-1 px-2 flex flex-row gap-x-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="text-xs font-medium">Запуск</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#272727] rounded-2xl py-[18px] inline-flex px-6 flex-col gap-y-2">
                                <div className="flex flex-col gap-y-[1px] items-start">
                                    <img src="static/AdheartFull.svg" alt="AdHeart Service Icon" className="select-none w-9 h-9" draggable="false" />
                                    <span className="font-bold font-['Inter'] text-lg md:text-[27px]">AdHeart</span>
                                    <span className="font-medium text-sm -mt-1">#SPY - Сервіс</span>
                                </div>
                                <div className="flex justify-between flex-row items-center">
                                    <span className="mr-28 font-medium text-xs">Доступ до: 15.11.2025</span>
                                    <div className="border-2 border-gray-200 rounded-md py-1 px-2 flex flex-row gap-x-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="text-xs font-medium">Запуск</span>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#272727] rounded-2xl py-[18px] inline-flex px-6 flex-col gap-y-2">
                                <div className="flex flex-col gap-y-[1px] items-start">
                                    <img src="static/MidjourneyFull.svg" alt="Midjourney Service Icon" className="select-none w-9 h-9" draggable="false" />
                                    <span className="font-bold font-['Inter'] text-lg md:text-[27px]">Midjourney</span>
                                    <span className="font-medium text-sm -mt-1">#Нейромережа</span>
                                </div>
                                <div className="flex justify-between flex-row items-center">
                                    <span className="mr-28 font-medium text-xs">Доступ до: 21.01.2027</span>
                                    <div className="border-2 border-gray-200 rounded-md py-1 px-2 flex flex-row gap-x-1 items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                                            <path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" />
                                        </svg>
                                        <span className="text-xs font-medium">Запуск</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[38.6px] flex flex-col text-center items-center gap-y-[15.77px] text-[#909090]">
                        <div className="inline-flex flex-row items-center gap-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                            <span className="font-['Inter'] text-left text-xs md:text-[15.6px]">
                                Легкий вхід без величезних інструкцій, невідомих архівів, антидетектів, проксі та юзер агентів
                            </span>
                        </div>
                        <div className="inline-flex flex-row items-center gap-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                            <span className="font-['Inter'] text-left text-xs md:text-[15.6px]">
                                Ми зробили все, щоб виключити бани. А якщо вони і відбуваються - ми можемо надати швидку заміну
                            </span>
                        </div>
                        <div className="inline-flex flex-row items-center gap-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-3 md:size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                            <span className="font-['Inter'] text-left text-xs md:text-[15.6px]">
                                Видача доступа за 1 хвилину після оплати
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col text-center mt-[79.8px]">
                    <h2 className="font-['Inter'] font-bold text-lg md:text-3xl">
                        Як це працює?
                    </h2>
                    <p className="mt-[15.59px] text-[#909090] font-['Inter'] text-sm md:text-lg flex flex-col text-center">
                        Ми впевнені, що Вам стало цікаво як це все працює. Чому все так легко, дешево та без банів?
                    </p>
                    <div className="grid grid-cols-1 xl:grid-cols-2 w-full mt-[43px] gap-y-[26.59px] xl:gap-y-0 xl:gap-x-[28px]">
                        <div className="grid gap-y-[18px]">
                            <div className="bg-[#161616] border border-[#252525] px-[27px] py-[24px] rounded-[48px] text-left w-full max-w-[343px] md:max-w-[640px] mx-auto">
                                <h4 className="font-['Inter'] font-semibold text-lg text-[#F3F3F3]">Чому виходить так дешево?</h4>
                                <span className="mt-[10px] text-[#909090] font-['Inter'] text-sm">
                                    Підписка на сервіси виходить досить дешева, тому що ми працюємо по принципу “Складчини” або як ще кажуть “Сімейна підписка”. Це коли на один акаунт додається декілька людей. Варто наголосити, що є сервіси де підписка видається саме на власний акаунт. Також важливо зазначити, що на кожний сервіс додається своя кількість користувачів для комфортного використання. Все залежить від лімітів, котрі встановлює сервіс. Наприклад на акаунт ChatGPT ми додаємо не більш ніж 4-5 людей. Завдяки цьому ми ніколи не стикаємося з проблемами коли комусь не вистачає промптів. Детальніше про кожний сервіс можете дізнатися у нашого адміна. Написати Ви йому можете в Telegram. Шукайте за ніком @White_Tools_Support.
                                </span>
                            </div>
                            <div className="bg-[#161616] px-[27px] py-[24px] rounded-[32px] w-full text-left max-w-[343px] md:max-w-[640px] mx-auto">
                                <h4 className="font-['Inter'] font-semibold text-lg text-[#F3F3F3]">Чому не буває банів та навіщо браузер?</h4>
                                <span className="mt-[10px] text-[#909090] font-['Inter'] text-sm">
                                    Зазвичай на акаунти сервісів подібних нашому прилітають бани за те, що їх ловить система безпеки. Досить підозріло, коли до прикладу на акаунт заходить користувач спочатку з Києва та пристроя iMac, а через 15 хвилин він вже сидить у Чернівцях на ноутбуці з Windows. А тепер представте, коли так одночасно може зайти 5 юзерів. Звісно кожен власник сервісу хоче, щоб кожен з цих 5 користувачів заплатив кошти. Через це системи безпеки блокують так часто акаунти наших конкурентів. Саме тому ми зробили те, що не робив ніхто! Ми створили браузер на основі Google Chrome, котрий автоматично ставить всі необхідні параметри для входу в акаунт. По такій самій логіці працюють антидетект браузери.
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="w-full relative">
                                <div className="bg-gradient-to-r from-[#00CFE1] to-transparent rotate-45 blur-[246px] w-full h-[130px] -z-20 lg:mt-36 relative pointer-events-none"></div>
                                <div className="xl:absolute w-full -mt-[130px] lg:-mt-[274px]">
                                    <div className="mx-auto rounded-[48px] border border-[#252525] bg-black wtHowServiceWorks bg-cover w-[343px] md:w-[640px] h-[207.41px] md:h-[387px] z-10"></div>
                                    <a href="#" className="rounded-full bg-white px-6 py-[10px] inline-flex flex-row items-center gap-x-[5px] xl:-mt-12 z-20 transition-all duration-200 hover:brightness-75">
                                        <span className="font-['Inter'] font-medium text-[15px] text-black">Переглянути як працює браузер</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                        </svg>
                                    </a>
                                    <div>
                                        <div className="inline-flex mt-[21px] flex-col gap-y-4 text-[#909090]">
                                            <div className="flex flex-row items-center gap-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                                <span className="font-['Inter'] text-left text-[15.6px]">Все автоматизовано. Більше ніяких запар з антіками, входами, куками і тд.</span>
                                            </div>
                                            <div className="flex flex-row items-center gap-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                                <span className="font-['Inter'] text-left text-[15.6px]">Сервіси можна використовувати у будь-який момент 24/7</span>
                                            </div>
                                            <div className="flex flex-row items-center gap-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                                <span className="font-['Inter'] text-left text-[15.6px]">Немає ніяких обмежень та проблем з лімітами</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[76px] w-full">
                    <h2 className="mt-[79.8px] font-bold text-[#F3F3F3] font-['Inter'] lg:text-[32.4px] text-lg md:text-[21px] text-center">
                        З нами можна економити з комфортом
                    </h2>
                    <p className="mt-[10.5px] text-[#909090] font-['Inter'] text-[13.7px] md:text-[15.6px] lg:text-lg text-center">
                        Ми забезпечуємо швидкий, дешевий та головне зручний доступ до сервісів
                    </p>
                    <div className="flex flex-col mt-[15.6px]">
                        <div className="grid md:grid-cols-2 gap-x-[23px] gap-y-[25px] md:gap-y-0">
                            <div className="bg-[#161616] border border-[#353535] rounded-[32px] flex flex-col xl:flex-row gap-x-[37px] w-full justify-between">
                                <div className="pb-[24px] pt-[30px] pl-[35px]">
                                    <h4 className="text-[#F3F3F3] font-['Inter'] font-semibold text-lg">
                                        Використовуй сервіси легко
                                    </h4>
                                    <p className="mt-[5px] font-['Inter'] text-[13.7px] text-[#909090] max-w-[258px]">
                                        Без величезних інструкцій по входу, антидектів, проксі і так далі. Достатньо просто натиснути на кнопку “Запуск” та наш браузер автоматично увійде в сервіс, котрий оберете.
                                    </p>
                                    <a href="#" className="mt-[29px] bg-white rounded-[7px] inline-flex flex-row items-center gap-x-[5px] py-5 xl:py-[10px] px-[59.5px] transition-all duration-200 hover:brightness-75">
                                        <span className="font-['Inter'] font-medium text-black text-[13px]">
                                            Подивитися огляд
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="wtUseServicesSimple lg:max-w-[276px] w-full h-full max-h-[164px] xl:max-h-[276px] xl:block hidden"></div>
                                <img src="static/wtMobileServices.svg" alt="WhiteTools Mobile Art of Services" draggable="false" className="w-full select-none xl:hidden" />
                            </div>
                            <div className="bg-[#161616] border border-[#353535] rounded-[32px] flex flex-col xl:flex-row gap-x-[8px] w-full justify-between">
                                <div className="pb-[24px] pt-[30px] pl-[35px]">
                                    <h4 className="text-[#F3F3F3] font-['Inter'] font-semibold text-lg">
                                        Довершена система
                                    </h4>
                                    <p className="mt-[5px] font-['Inter'] text-[13.7px] text-[#909090] max-w-[274px]">
                                        Ми зробили все, щоб Ви користувалися сервісами без проблем, лімітів та банів.

                                        Ми гарантуємо швидке надання доступу протягом 5хв, щоб ви могли безперешкодно користуватися всіма  можливостями сервісів, котрі придбали.
                                    </p>
                                    <a href="#" className="mt-[29px] bg-white rounded-[7px] inline-flex flex-row items-center gap-x-[5px] py-[10px] px-[59.5px] transition-all duration-200 hover:brightness-75">
                                        <span className="font-['Inter'] font-medium text-black text-[13px]">
                                            Придбати доступ
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 text-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="wtStopNinja max-w-[284px] w-full h-full max-h-[276px] bg-cover xl:block hidden"></div>
                                <img src="static/wtMobileNinja.svg" alt="WhiteTools Mobile Art of Ninja" draggable="false" className="w-full select-none xl:hidden -mt-2" />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-0 mt-6 lg:mt-[39px]">
                            <div className="bg-[#161616] border border-[#252525] rounded-[33px] px-[25px] pt-[25px] pb-[9px] flex flex-col gap-y-[19px]">
                                <div>
                                    <h4 className="font-['Inter'] font-semibold text-lg text-[#F3F3F3] mb-2">Економія</h4>
                                    <span className="font-['Inter'] text-[#909090] text-sm">
                                        Щомісячно заощаджуй до -90% власних коштів на сервісах. Ми завжди стараємося ставити дуже низькі ціни. Щоб Ви могли користуватися хорошим продуктом не тільки якісно, а і дешево
                                    </span>
                                </div>
                                <div className="w-full">
                                    <div className="rounded-[21px] lg:mx-5 flex items-center justify-center">
                                        <div className="wtSaveUpTo90PercentsIllustration bg-no-repeat bg-center rounded-md w-full sm:max-w-[280px] max-w-[280px] min-h-[251px] max-h-[251px] h-full"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#161616] border border-[#252525] rounded-[33px] px-[25px] pt-[18.6px] pb-[25px] flex flex-col gap-y-6">
                                <div className="w-full">
                                    <div className="mx-[14px]">
                                        <div className="wtManyServicesAvailableIllustration bg-no-repeat bg-center bg-cover min-h-[251px] max-h-[251px] h-full"></div>
                                        <hr className="mt-4 h-1 bg-gradient-to-r from-[#34B7C233] via-[#34B7C2] to-[#34B7C233] outline-none border-none rounded-full" />
                                    </div>
                                </div>
                                <div>
                                    <h4 className="font-['Inter'] font-semibold text-lg text-[#F3F3F3] mb-2">Більше 15 сервісів вже у наявності</h4>
                                    <span className="font-['Inter'] text-[#909090] text-sm">
                                        У нашому арсеналі вже більш ніж 15 сервісів, котрі є завжди у наявності. З часом наш список тільки поповнюється.
                                    </span>
                                </div>
                            </div>
                            <div className="bg-[#161616] border border-[#252525] rounded-[33px] px-[25px] pt-[25px] pb-[32px] flex flex-col md:flex-row justify-between xl:justify-normal xl:flex-col gap-y-[21px] mt-6 xl:mt-0 md:col-span-2 xl:col-span-1">
                                <div>
                                    <h4 className="font-['Inter'] font-semibold text-lg text-[#F3F3F3] mb-2">Ми завжди на зв’язку</h4>
                                    <span className="font-['Inter'] text-[#909090] text-sm mr-2">
                                        Ми постійно онлайн та готові вирішити всі Ваші питання або проблеми. Зв’язатися з нами Ви можете в Telegram
                                    </span>
                                    <a href="#" className="bg-white rounded-[7px] flex-row items-center mt-1.5 xl:mt-0 justify-center gap-x-[6px] px-[35px] py-2 xl:py-[5.6px] flex xl:inline-flex transition-all duration-200 hover:brightness-75">
                                        <span className="font-['Inter'] font-medium text-base text-black">Написати</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[19px] text-black">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="w-full xl:max-w-full">
                                    <div className="mx-[14px] justify-center md:justify-end xl:justify-center flex">
                                        <img src="static/wtSupportIllustration.svg" alt="WhiteToolsBrowser Support Illustration" draggable="false" className="select-none md:h-64 lg:h-80 xl:h-auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[47px] rounded-[22px] bg-[#01D4E6] pl-4 md:pl-[30px] pr-[8px] md:pr-[34px] py-[14px] md:py-[25px] flex flex-row items-center justify-between w-full">
                            <span className="font-['Inter'] font-semibold text-[10px] md:text-xl lg:text-2xl text-black">
                                Спробуй White Tools Browser вже сьогодні!
                            </span>
                            <a href="#" className="font-['Inter'] text-[#CCCCCC] font-medium text-[9px] text-center md:text-[13.9px] px-[13px] py-[8.5px] bg-[#161616] rounded-full border border-[#252525] transition-all duration-200 hover:brightness-125">
                                Придбати доступ
                            </a>
                        </div>
                        <div className="mt-[160px] flex flex-col gap-y-8">
                            <h3 className="font-['Inter'] font-light text-[#909090] text-3xl lg:text-[52.8px] lg:leading-none flex flex-col">
                                <span>Ми прагнемо бути кращими</span>
                                <span>Від цін до зручності.</span>
                            </h3>
                            <h3 className="font-['Inter'] font-light text-3xl lg:text-[52.8px] lg:leading-none">
                                Наша команда хоче створити найкращий інструмент для Digital-спеціалістів, щоб економити можна було просто, дешево та комфортно.
                            </h3>
                        </div>
                        <div className="mt-[146px] flex flex-col">
                            <div className="flex flex-col text-center">
                                <h5 className="font-['Inter'] font-bold text-lg md:text-[21px] lg:text-[32px] text-[#F3F3F3]">
                                    Що про нас кажуть люди?
                                </h5>
                                <span className="underline font-['Inter'] text-[13.7px] md:text-lg mt-0.5 text-[#F3F3F3] transition-all duration-200 hover:text-white cursor-pointer">
                                    Підписуйся на нас в Telegram
                                </span>
                            </div>
                            <div className="xl:grid flex xl:grid-cols-3 gap-x-6 mt-5 overflow-x-scroll xl:overflow-hidden pb-6 wtScroller">
                                <div className="flex flex-col gap-y-6">
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[15px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/AnAsUserReviewAvatar.webp" alt="An As's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">An As</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@arsant888</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-[15.28px]">
                                            <img src="static/an-as-chat-bubble.svg" className="select-none" alt="An As's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[35px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/ViktoriiaUserReviewAvatar.webp" alt="Viktoriia's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">Viktoriia</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@VikaCh_target</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-[19px]">
                                            <img src="static/viktoriia-chat-bubble.svg" className="select-none" alt="Viktoriia's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[30px] pt-[14px] pb-[16px]">
                                        <h4 className="font-['Inter'] font-semibold text-lg text-white">Більше відгуків</h4>
                                        <span className="font-['Inter'] text-[13.7px] text-[#909090]">Можна переглянути в нашому Telegram каналі</span>
                                        <a href="#" className="bg-white block text-center py-[10px] mt-[11px] rounded-lg transition-all duration-200 hover:brightness-75">
                                            <span className="font-['Inter'] font-semibold text-[13.7px] text-black">Перейти до Telegram каналу</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-6">
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[32px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/MaksymUserReviewAvatar.webp" alt="Максим's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">Максим</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@Maks_Zetrebko</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-[19px]">
                                            <img src="static/maskym-chat-bubble.svg" className="select-none" alt="Максим's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[25px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/VladyslavUserReviewAvatar.webp" alt="Владислав's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">Владислав</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@vlad_prumak</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-[25px]">
                                            <img src="static/vladyslav-chat-bubble.svg" className="select-none" alt="Владислав's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-6">
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[25px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/ZhoraNaygirshyhUserReviewAvatar.webp" alt="Жора Найгiрших's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">Жора Найгiрших</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@naigirshiy_admin</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <img src="static/zhora-naygirshyh-chat-bubble.svg" className="select-none" alt="Жора Найгiрших's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                    <div className="bg-[#161616] border border-[#1B1B1B] rounded-[33px] w-full min-w-[396px] px-[25px] pt-[25px] pb-[28px] transition-all duration-200 hover:brightness-125">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <div>
                                                    <img src="static/GryshaUserReviewAvatar.webp" alt="Гриша's Avatar" draggable="false" className="select-none rounded-full" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="font-['Inter'] font-medium text-base text-[#F3F3F3]">Гриша</span>
                                                    <span className="font-['Inter'] text-xs text-[#909090]">@Grisha_Union</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5ZM11.9121 8.48981C10.7936 8.95505 8.55805 9.91798 5.20556 11.3786C4.66117 11.5951 4.376 11.8069 4.35004 12.014C4.30616 12.3639 4.74443 12.5017 5.34125 12.6894C5.42243 12.7149 5.50654 12.7414 5.59277 12.7694C6.17994 12.9603 6.96979 13.1836 7.3804 13.1924C7.75286 13.2005 8.16857 13.0469 8.62753 12.7318C11.7599 10.6174 13.3768 9.54865 13.4783 9.52561C13.5499 9.50936 13.6492 9.48892 13.7164 9.54869C13.7836 9.60845 13.777 9.72164 13.7699 9.752C13.7265 9.93709 12.0061 11.5365 11.1158 12.3642C10.8383 12.6222 10.6414 12.8053 10.6012 12.8471C10.511 12.9407 10.4191 13.0293 10.3308 13.1144C9.78532 13.6403 9.37625 14.0346 10.3535 14.6786C10.8231 14.988 11.1988 15.244 11.5737 15.4993C11.9832 15.7781 12.3915 16.0562 12.9199 16.4025C13.0545 16.4907 13.183 16.5824 13.3083 16.6717C13.7847 17.0113 14.2128 17.3165 14.7416 17.2679C15.0489 17.2396 15.3664 16.9506 15.5276 16.0888C15.9085 14.0522 16.6574 9.63935 16.8305 7.82093C16.8456 7.66162 16.8265 7.45772 16.8112 7.36822C16.7959 7.27871 16.7639 7.15119 16.6476 7.05678C16.5098 6.94498 16.2971 6.9214 16.2019 6.92308C15.7694 6.9307 15.1058 7.16145 11.9121 8.48981Z" fill="white"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="mt-[24px]">
                                            <img src="static/grysha-chat-bubble.svg" className="select-none" alt="Гриша's Chat Bubble" draggable="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[25px]">
                            <h3 className="font-['Inter'] text-lg text-medium text-center md:text-left md:font-bold md:text-2xl text-[#F3F3F3] max-w-[792px] w-full mx-auto mt-[71.9px]">
                                Часті запитання:
                            </h3>
                            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} className="flex flex-col gap-3 mt-9">
                                <AccordionItem className="border border-[#252525] rounded-lg bg-[#161616] p-[13px] w-full max-w-[792px] mx-auto transition-all duration-200 hover:border-gray-100">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex flex-row gap-x-2 items-center select-none outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[18px] text-[#F3F3F3]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                            </svg>
                                            <span className="text-[#F3F3F3] font-['Inter'] text-[13.7px]">
                                                Чому ціна на сервіси така дешева?
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="pl-[26px]">
                                        <p className="font-['Inter'] text-[#909090] text-xs">
                                            Тому що ми працюємо по принципу  "складчини" або ще це називають "сімейна підписка". Коли на один акаунт додається декілька людей. До прикладу на ChatGPT додається 4-5 людей. Завдяки цьому ми не стикаємося з проблемами, коли комусь не вистачає промптів. Детальніше про кожен сервіс можете дізнатися у адміна @White_Tools_Browser
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="border border-[#252525] rounded-lg bg-[#161616] p-[13px] w-full max-w-[792px] mx-auto transition-all duration-200 hover:border-gray-100">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex flex-row gap-x-2 items-center select-none outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[18px] text-[#F3F3F3]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                            </svg>
                                            <span className="text-[#F3F3F3] font-['Inter'] text-[13.7px]">
                                                Чи можна використовувати на Windows та Mac?
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="pl-[26px] transition-all duration-200">
                                        <p className="font-['Inter'] text-[#909090] text-xs">
                                            Так, можна. Ми зробили все, щоб використання було зручне як на Windows так і на Mac.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="border border-[#252525] rounded-lg bg-[#161616] p-[13px] w-full max-w-[792px] mx-auto transition-all duration-200 hover:border-gray-100">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex flex-row gap-x-2 items-center select-none outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[18px] text-[#F3F3F3]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                            </svg>
                                            <span className="text-[#F3F3F3] font-['Inter'] text-[13.7px]">
                                                Що буде якщо акаунт все-таки заблокують?
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="pl-[26px]">
                                        <p className="font-['Inter'] text-[#909090] text-xs">
                                            Зазвичай ми майже не стикаємося з блокуваннями через довершену систему входу. Але якщо таке трапляється - ми швидко та безкоштовно видаємо заміну акаунта, щоб Ви могли далі використовувати сервіс, котрий придбали.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="border border-[#252525] rounded-lg bg-[#161616] p-[13px] w-full max-w-[792px] mx-auto transition-all duration-200 hover:border-gray-100">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex flex-row gap-x-2 items-center select-none outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[18px] text-[#F3F3F3]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                            </svg>
                                            <span className="text-[#F3F3F3] font-['Inter'] text-[13.7px]">
                                                Як у Вас придбати доступ до сервісу?
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="pl-[26px]">
                                        <p className="font-['Inter'] text-[#909090] text-xs">
                                            Придбати доступ можна через нашого адміна. Знайти його можна в телеграм під ніком @White_Tools_Browser. Або натискай на кнопку “Придбати” 
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="border border-[#252525] rounded-lg bg-[#161616] p-[13px] w-full max-w-[792px] mx-auto transition-all duration-200 hover:border-gray-100">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className="flex flex-row gap-x-2 items-center select-none outline-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-[18px] text-[#F3F3F3]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                            </svg>
                                            <span className="text-[#F3F3F3] font-['Inter'] text-[13.7px]">
                                                Можна купити доступ одразу на 3/6/12 місяців?
                                            </span>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="pl-[26px]">
                                        <p className="font-['Inter'] text-[#909090] text-xs">
                                            Так, звісно. Доступ можна придбати одразу на декілька місяців. Також за те, що ви купуєте “оптом” - ми готові надати Вам гарну знижку. Детальніше можна обговорити з адміном
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div className="flex justify-center mt-[67px] mb-[58px]">
                            <a href="#" className="bg-gradient-to-r from-[#00CFE1] to-[#00C3D4] py-[17px] md:py-[29px] px-[96px] rounded-[22px] transition-all duration-200 hover:brightness-125">
                                <span className="font-['Inter'] font-semibold text-base md:text-2xl text-black">Придбати доступ</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Index;
