import React, {useState} from "react"
const Navbar: React.FC = () => {
    const [menuState, setMenuState] = useState(false);
    return (
        <>
            <header className={ !menuState ? "w-full flex sticky justify-between px-[25px] h-12 bg-[#0E0E0E] rounded-b-3xl" : "w-full flex sticky justify-between px-[25px] h-12 bg-[#0E0E0E]" }>
                <div className="flex items-center">
                    <img src="static/logo.webp" alt="WhiteToolsBrowser Logo" draggable="false" className="h-[30px]" />
                </div>
                <div className="lg:flex items-center gap-x-4 hidden">
                    <a href="/download" className="text-[#909090] font-['Inter'] transition-all duration-200 hover:text-white">Завантажити</a>
                </div>
                <div className="w-full max-w-[73.56px] flex justify-end">
                    <button className="h-full lg:hidden" onClick={() => setMenuState(!menuState!)}>
                        {!menuState ? (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        )}
                    </button>
                </div>
            </header>
            <nav className={ !menuState ? "hidden" : "bg-[#0E0E0E] px-[25px] py-[14px]"}>
                <div>
                    <ul>
                        <li>
                            <a href="/download" className="text-[#909090] font-['Inter'] transition-all duration-200 hover:text-white">Завантажити</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar;